export const HOME_ROUTE = '/';
export const SIGNIN_ROUTE = '/signin';

export const EDIT_ROUTE = 'edit';

export const PATIENT_ROUTE = '/patient/:id';
export const PATIENT_DETAILS_ROUTE = 'details';
export const PATIENT_CREATE_ROUTE = '/create-patient';

export const REPORTS_ROUTE = 'reports';
export const REPORT_DETAILS_ROUTE = 'reports/:reportId';
export const REPORT_EDIT_ROUTE = `reports/:reportId/${EDIT_ROUTE}`;
export const REPORT_CREATE_ROUTE = 'create-report';
