import { Document, ResourceTable } from '@medplum/react';

import { usePatient } from '../../../../hooks/usePatient';

export const PatientDetails = () => {
  const patient = usePatient();

  if (!patient) {
    return null;
  }

  return (
    <Document>
      <ResourceTable value={patient} />
    </Document>
  );
};