import { Navigate, Route, Routes } from 'react-router-dom';

import { useMedplumProfile } from '@medplum/react';

import { HomePage } from '../pages/HomePage';
import { CreatePatientPage } from '../pages/CreatePatientPage';
import { SignInPage } from '../pages/SignInPage';
import { PatientPage } from '../pages/PatientPage';
import { PatientDetails } from '../pages/PatientPage/components/PatientDetails';
import { PatientEdit } from '../pages/PatientPage/components/PatientEdit';
import { PatientReports } from '../pages/PatientPage/components/PatientReports';
import { ReportDetails } from '../pages/PatientPage/components/PatientReports/components/ReportDetails';
import { CreateReport } from '../pages/PatientPage/components/PatientReports/components/CreateReport';
import { ReportEdit } from '../pages/PatientPage/components/PatientReports/components/ReportEdit';
import {
  HOME_ROUTE,
  PATIENT_ROUTE,
  PATIENT_DETAILS_ROUTE,
  EDIT_ROUTE,
  REPORTS_ROUTE,
  REPORT_CREATE_ROUTE,
  REPORT_DETAILS_ROUTE,
  REPORT_EDIT_ROUTE,
  SIGNIN_ROUTE,
  PATIENT_CREATE_ROUTE,
} from './routes';

export const AppRoutes = () => {
  const profile = useMedplumProfile();

  return (
    <Routes>
      {profile ? (
        <>
          <Route path={HOME_ROUTE} element={<HomePage />} />
          <Route path={PATIENT_ROUTE} element={<PatientPage />}>
            <Route path={PATIENT_DETAILS_ROUTE} element={<PatientDetails />} />
            <Route path={EDIT_ROUTE} element={<PatientEdit />} />
            <Route path={REPORTS_ROUTE} element={<PatientReports />} />
            <Route path={REPORT_DETAILS_ROUTE} element={<ReportDetails />} />
            <Route path={REPORT_EDIT_ROUTE} element={<ReportEdit />} />
            <Route path={`${REPORTS_ROUTE}/${REPORT_CREATE_ROUTE}`} element={<CreateReport />} />
          </Route>
          <Route path={PATIENT_CREATE_ROUTE} element={<CreatePatientPage />} />
          <Route path={SIGNIN_ROUTE} element={<SignInPage />} />
        </>
      ) : (
        <>
          <Route path={SIGNIN_ROUTE} element={<SignInPage />} />
          <Route path="*" element={<Navigate to={SIGNIN_ROUTE} replace />} />
        </>
      )}
    </Routes>
  );
}
