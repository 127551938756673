import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Document, ResourceForm, useMedplum } from '@medplum/react';
import { DiagnosticReport, Resource } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';

import { ResourceType } from '../../../../../../enums/resourceType';

export const CreateReport = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const { id } = useParams();

  const defaultValue: Partial<DiagnosticReport> = useMemo(() => ({
    resourceType: ResourceType.DIAGNOSTIC_REPORT,
    subject: {
      reference: `${ResourceType.PATIENT}/${id}`,
    }
  }), [id])
  
  const handleSubmit = (resource: Resource) => {
    medplum
      .createResource(resource)
      .then((newReport) => {
        navigate(`/Patient/${id}/Reports/${newReport.id}`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        showNotification({
          color: 'red',
          message: normalizeErrorString(err),
          autoClose: false,
          styles: { description: { whiteSpace: 'pre-line' } },
        });
      });
  }

  return (
    <Document>
      <ResourceForm
        defaultValue={defaultValue}
        onSubmit={handleSubmit}
      />
    </Document>
  );
};