import { useParams } from 'react-router-dom';

import { Patient } from '@medplum/fhirtypes';
import { useResource } from '@medplum/react';

import { ResourceType } from '../enums/resourceType';

export const usePatient = () => {
  const { id } = useParams();

  if (!id) {
    throw new Error('Patient ID not found');
  }
  
  return useResource<Patient>({ reference: `${ResourceType.PATIENT}/${id}` });
}
