import { Outlet, useNavigate } from 'react-router-dom';

import { Grid, Loader, Tabs } from '@mantine/core';
import { PatientSummary } from '@medplum/react';

import { PatientTabs } from './tabs';
import { useTabFromLocation } from './utils';
import { usePatient } from '../../hooks/usePatient';

export const PatientPage = () => {
  const patient = usePatient();
  const navigate = useNavigate();
  const currentTab = useTabFromLocation();

  const handleTabChange = (newTab: string | null) => {
    navigate(`/Patient/${patient!.id}/${newTab ?? ''}`);
  }

  if (!patient) {
    return <Loader />;
  }

  return (
    <Grid>
      <Grid.Col span={4}>
        <PatientSummary patient={patient} appointmentsUrl={undefined} encountersUrl={undefined} />
      </Grid.Col>
      <Grid.Col span={8}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tabs.List mb="xs">
            {PatientTabs.map((value, index) => (
              <Tabs.Tab value={value} key={index}>
                {value}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
        <Outlet/>
      </Grid.Col>
    </Grid>
  );
}
