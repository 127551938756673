import { HumanName } from "@medplum/fhirtypes";

export const getProfileName = (name?: HumanName[]) => {
  let profileName = '';
  if (name) {
    profileName += name[0].suffix ? ' ' + name[0].suffix[0] : '';
    profileName += name[0].given ? ' ' + name[0].given[0] : '';
    profileName += name[0].family ? ' ' + name[0].family : '';
    profileName += name[0].prefix ? ' ' + name[0].prefix[0] : '';
  }

  return profileName;
};