import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import { showNotification } from "@mantine/notifications";
import { getReferenceString, normalizeErrorString } from "@medplum/core";
import { Patient, Practitioner, Resource } from "@medplum/fhirtypes";
import { Document, ResourceForm, useMedplum, useMedplumProfile } from "@medplum/react";

import { ResourceType } from "../../enums/resourceType";
import { getProfileName } from "../../utils/getProfileName";

export const CreatePatientPage = () =>  {
  const profile = useMedplumProfile() as Practitioner;
  const medplum = useMedplum();
  const navigate = useNavigate();

  const defaultValue: Partial<Patient> = useMemo(() => ({
    resourceType: ResourceType.PATIENT,
    generalPractitioner: [{
      reference: getReferenceString(profile),
      display: getProfileName(profile.name)
    }]
  }), [profile])
  
  const handleCreatePatient = (newResource: Resource) => {
    medplum
      .createResource(newResource)
      .then((newPatient) => {
        navigate(`/Patient/${newPatient.id}/Details`);
      })
      .catch((err) => {
        showNotification({
          color: 'red',
          message: normalizeErrorString(err),
          autoClose: false,
          styles: { description: { whiteSpace: 'pre-line' } },
        });
      });
  }

  return (
    <Document>
      <ResourceForm defaultValue={defaultValue} onSubmit={handleCreatePatient} />
    </Document> 
  );
}
