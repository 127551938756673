import { useNavigate } from 'react-router-dom';

import { Title } from '@mantine/core';
import { SignInForm } from '@medplum/react';

import { HOME_ROUTE } from '../../router/routes';
import { Logo } from '../../components/logo';

export const SignInPage = () => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate(HOME_ROUTE)
  };

  return (
    <SignInForm
      googleClientId={import.meta.env.GOOGLE_CLIENT_ID}
      onSuccess={handleSuccess}
    >

      <Logo size={32} />
      <Title>Sign in to FrontEnd Demo</Title>
    </SignInForm>
  );
}
