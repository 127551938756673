import { useNavigate } from 'react-router-dom';

import { normalizeErrorString } from '@medplum/core';
import { Resource } from '@medplum/fhirtypes';
import { Document, ResourceForm, useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';

import { ResourceType } from '../../../../enums/resourceType';
import { usePatient } from '../../../../hooks/usePatient';
import { HOME_ROUTE } from '../../../../router/routes';

export const PatientEdit = () => {
  const patient = usePatient();
  const medplum = useMedplum();
  const navigate = useNavigate();

  const handlePatientEdit = (resource: Resource) => {
    medplum
      .updateResource(resource)
      .then((patient) => {
        showNotification({
          icon: <IconCircleCheck />,
          title: 'Success',
          message: 'Patient edited',
        });
        navigate(`/Patient/${patient.id}/details`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        showNotification({
          color: 'red',
          icon: <IconCircleOff />,
          title: 'Error',
          message: normalizeErrorString(err),
        });
      });
  };

  const handlePatientDelete = (resource: Resource) => {
    medplum
      .deleteResource(ResourceType.PATIENT, resource.id!)
      .then(() => {
        showNotification({
          icon: <IconCircleCheck />,
          title: 'Success',
          message: 'Patient deleted',
        });
        navigate(HOME_ROUTE);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        showNotification({
          color: 'red',
          icon: <IconCircleOff />,
          title: 'Error',
          message: normalizeErrorString(err),
        });
      });
  };

  if (!patient) {
    return null;
  }

  return (
    <Document>
      <ResourceForm defaultValue={patient} onSubmit={handlePatientEdit} onDelete={handlePatientDelete} />
    </Document>
  );
}
