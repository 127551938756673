import { Suspense } from 'react';

import {
  AppShell,
  Loading,
  useMedplum,
} from '@medplum/react';
import { IconPencil, IconUser } from '@tabler/icons-react';

import { AppRoutes } from './router/AppRoutes';
import { Logo } from './components/logo';

export const App = () => {
  const medplum = useMedplum();

  if (medplum.isLoading()) {
    return null;
  }

  return (
    <AppShell
      logo={<Logo size={24} />}
      menus={[
        {
          title: 'Patients',
          links: [
            { icon: <IconUser />, label: 'Assigned patients', href: '/' },
            { icon: <IconPencil />, label: 'New Patient', href: '/create-patient' }
          ],
        },
      ]}
      resourceTypeSearchDisabled={true}
    >
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </AppShell>
  );
}
