import { Outlet } from 'react-router-dom';

import { Title } from '@mantine/core';
import { Operator, SearchRequest, getReferenceString } from '@medplum/core';
import { Practitioner } from '@medplum/fhirtypes';
import { Document, ResourceName, SearchClickEvent, SearchControl, useMedplumNavigate, useMedplumProfile } from '@medplum/react';

import { ResourceType } from '../../enums/resourceType';
import { PatientTabs } from '../PatientPage/tabs';

export const HomePage = () => {
  const profile = useMedplumProfile() as Practitioner;
  const navigate = useMedplumNavigate();
  const search: SearchRequest = {
    resourceType: ResourceType.PATIENT,
    fields: ['name', 'birthDate'],
    filters: [{code: 'general-practitioner', operator: Operator.EQUALS, value: getReferenceString(profile)}]
  };

  const handlePatientClick = (event: SearchClickEvent) => {
    navigate(`/${getReferenceString(event.resource)}/${PatientTabs[0]}`)
  }

  return (
    <Document>
      <Title>
        <ResourceName value={profile} link />
      </Title>
      <SearchControl
        search={search}
        onClick={handlePatientClick}
        hideFilters
        hideToolbar
      />
      <Outlet />
    </Document>
  );
}
