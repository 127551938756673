import { useNavigate, useParams } from 'react-router-dom';

import { Document, ResourceForm, useMedplum } from '@medplum/react';
import { Resource } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';
import { normalizeErrorString } from '@medplum/core';

import { useReport } from '../../../../../../hooks/useReport';
import { ResourceType } from '../../../../../../enums/resourceType';

export const ReportEdit = () => {
  const { id } = useParams();
  const medplum = useMedplum();
  const navigate = useNavigate();
  const report = useReport();

  const handleReportEdit = (resource: Resource) => {
    medplum
      .updateResource(resource)
      .then((report) => {
        showNotification({
          icon: <IconCircleCheck />,
          title: 'Success',
          message: 'Report edited',
        });
        navigate(`/Patient/${id}/Reports/${report.id}`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        showNotification({
          color: 'red',
          icon: <IconCircleOff />,
          title: 'Error',
          message: normalizeErrorString(err),
        });
      });
  };

  const handleReportDelete = (resource: Resource) => {
    medplum
      .deleteResource(ResourceType.DIAGNOSTIC_REPORT, resource.id!)
      .then(() => {
        showNotification({
          icon: <IconCircleCheck />,
          title: 'Success',
          message: 'Report deleted',
        });
        navigate(`/Patient/${id}/Reports`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        showNotification({
          color: 'red',
          icon: <IconCircleOff />,
          title: 'Error',
          message: normalizeErrorString(err),
        });
      });
  };

  if (!report) {
    return null;
  };

  return (
    <Document>
      <ResourceForm defaultValue={report} onSubmit={handleReportEdit} onDelete={handleReportDelete} />
    </Document>
  );
};