import { useNavigate } from 'react-router-dom';

import { Document, SearchClickEvent, SearchControl } from '@medplum/react';
import { Operator, SearchRequest } from '@medplum/core';
import { Button } from '@mantine/core';

import { usePatient } from '../../../../hooks/usePatient';
import { ResourceType } from '../../../../enums/resourceType';
import { REPORT_CREATE_ROUTE } from '../../../../router/routes';

export const PatientReports = () => {
  const patient = usePatient();
  const navigate = useNavigate();
  
  const search: SearchRequest = {
    resourceType: ResourceType.DIAGNOSTIC_REPORT,
    fields: ['category', 'code', 'status', '_lastUpdated'],
    filters: [{code: 'patient', operator: Operator.EQUALS, value: patient!.id!}]
  };

  const handleReportClick = (e: SearchClickEvent) => {
    navigate(`${e.resource.id}`);
  };

  const handleCreateNewClick = () => {
    navigate(REPORT_CREATE_ROUTE);
  };

  if (!patient) {
    return null;
  };

  return (
    <Document>
      <Button onClick={handleCreateNewClick}>Create New</Button>
      <SearchControl
        search={search}
        onClick={handleReportClick}
        hideFilters
        hideToolbar
      />
    </Document>
  );
};