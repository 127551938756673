import { useNavigate } from 'react-router-dom';

import { Document, ResourceTable } from '@medplum/react';
import { Button, Group, Title } from '@mantine/core';

import { useReport } from '../../../../../../hooks/useReport';
import { EDIT_ROUTE } from '../../../../../../router/routes';

export const ReportDetails = () => {
  const report = useReport();
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(EDIT_ROUTE);
    window.scrollTo(0, 0);
  };

  if (!report) {
    return null;
  };

  return (
    <Document>
      <Title>Diagnostic Report Details</Title>
      <ResourceTable value={report} />
      <Group justify="right">
        <Button onClick={handleEditClick}>Edit</Button>
      </Group>
    </Document>
  );
};