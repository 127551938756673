import { useParams } from 'react-router-dom';

import { DiagnosticReport } from '@medplum/fhirtypes';
import { useResource } from '@medplum/react';

import { ResourceType } from '../enums/resourceType';

export const useReport = () => {
  const { reportId } = useParams();

  if (!reportId) {
    throw new Error('Report ID not found');
  }
  
  return useResource<DiagnosticReport>({ reference: `${ResourceType.DIAGNOSTIC_REPORT}/${reportId}` });
}
